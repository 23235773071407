import React, {useState} from 'react'
import Head from '../components/head'
import Layout from '../components/layout'
import '../styles/grid.scss'
import '../styles/index.scss'
import parolaSifirla from "../images/parolasifirla.svg"
import queryString from "query-string"



const ResetPassword = ({ location }) => {
const queryParams = queryString.parse(location.search)
const [formState, setFormState] = useState({password:"",resetToken:queryParams.token})
const [changed, setChanged] = useState(false)

const handleChange = (e) => {
  setFormState({ ...formState, [e.target.name]: e.target.value })
}

const handleSubmit = (e) => {
  e.preventDefault()
  fetch('https://api.cember.app/api/user/resetpassword', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(formState)
  })
  .then((res) => {
    console.log(res)
    setChanged(true)
  })
  .catch((error) => alert(error))
}

    if(changed){
      return (
        <Layout>
        <Head title="Parola Sıfırla" />
        <div className="row resetpassword">
         <div className="hero-container">
         <div className="container">
           <div className="col-12">
            <div className="col-6">
            
              <h1>Şifremi Sıfırla </h1>    
              <p>Yeni şifreni kaydettik, uygulamaya dönüp hemen giriş yapabilirsin.</p>
                   
            </div>
            <div className="col-6">
              <img src={parolaSifirla} alt="Şifremi Sıfırla" />
  
            </div>
            </div>
          </div>
        </div>
        </div>
          </Layout>
        
         
      )
    }else{
      return (
        <Layout>
        <Head title="Şifre Sıfırla" />
        <div className="row resetpassword">
         <div className="hero-container">
         <div className="container">
            <div className="col-6">
            
              <h1>Şifremi Sıfırla </h1>    
              <p>Yeni şifreni girdikten sonra uygulamaya dönerek giriş yapabilirsin.</p>
              <div className="col-12">
                <form onSubmit={handleSubmit}  name="reset"  method="post" >
                  <input required type="password" name="password" placeholder="Yeni parola" onChange={handleChange} />  
                  <button type="submit"  >Şifremi Kaydet</button>
                </form>
              </div>
             
            </div>
            <div className="col-6">
            <img src={parolaSifirla} alt="Şifremi Sıfırla" />
  
            </div>
          </div>
        </div>
        </div>
          </Layout>
        
         
      )
    }
    
}

export default ResetPassword